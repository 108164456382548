import request from '@/utils/request.js';
//如果走app相关的页面就在所有的请求路径上加上app
let baseUrl
if (location.href.includes('templateApp')) {
    baseUrl = 'App'
} else {
    baseUrl = ''
}

//上传图片  ${baseUrl}
export function imageUpload(formData, onUploadProgress) {
    return request()({
        url: `/api/custom/v1/pxt/task/fileUpload`,
        method: 'post',
        data: formData,
        onUploadProgress
    })
}

//获取省份 城市  地区
export function getProvince() {
    return request().get('/api/base/v1/areaselect/province/find')
}
export function getCity(Code) {
    return request().get(`/api/base/v1/areaselect/city/find?provinceCode=${Code}`)
}
export function getArea(Code) {
    return request().get(`/api/base/v1/areaselect/area/find?cityCode=${Code}`)
}