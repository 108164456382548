
import { defineComponent } from "vue";
// import moment from "moment";
export default defineComponent({
  props: {
    start: {
      type: String,
      default: null,
    },
    end: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: "执行时间",
    },
    size: {
      type: String,
      default: "default",
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: Array,
      default: [
        {
          required: true,
          trigger: "blur",
          validator: async (rule, value) => {
            if (!1) {
              return Promise.reject(`未传入校验规则`);
            }
          },
        },
      ],
    },
    disabledDateCb: {
      type: Function,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      formState: {
        date: [this.start, this.end],
      },
    };
  },
  methods: {
    disabledDate(current) {
      // return current && current < moment().endOf("day");
      // return current && current <= moment().add(-1, "days");
      return (this as any).disabledDateCb(current);
    },
    init() {
      (this as any).formState.date = [(this as any).start, (this as any).end];
    },
    change(value) {
      // console.log(value);
      this.$emit(
        "update:start",
        this.formState.date[0] ? this.formState.date[0] + " 00:00:00" : null
      );
      this.$emit(
        "update:end",
        this.formState.date[1] ? this.formState.date[1] + " 23:59:59" : null
      );
      //   this.$emit("change", value);
    },
  },
  watch: {
    start() {
      this.init();
    },
    end() {
      this.init();
    },
  },
});
