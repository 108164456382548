
import { defineComponent } from "vue";
import Date from "@/components/task/base/Date.vue";
import moment from "moment";
export default defineComponent({
  components: {
    Date,
  },
  props: {
    formState: {
      type: Object,
      default: {},
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      rules: {
        taskInfoTitle: [
          {
            required: true,
            message: "请输入任务名称",
            trigger: "blur",
          },
        ],
        typeCode: [
          {
            required: true,
            message: "请选择任务类型",
            trigger: "blur",
          },
        ],
        taskInfoCode: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
        performAudit: [
          {
            required: true,
            validator: async (rule, value) => {
              if (value !== 0 && value !== 1) {
                return Promise.reject("请选择审核需求");
              }
            },
            trigger: "change",
          },
        ],
        date: [
          {
            required: true,
            trigger: "change",
            validator: async (rule, value) => {
              if (!this.formState.planStartTime) {
                return Promise.reject(`请选择执行时间`);
              }
              let diff = moment(moment()).diff(
                moment(this.formState.planStartTime),
                "days"
              );
              if (diff > 0) {
                return Promise.reject(`任务执行开始时间不能早于当前时间`);
              }
            },
          },
        ],
      },
    };
  },
  methods: {
    disabledDateCb(current) {
      return current && current <= moment().add(-1, "days");
    },
  },
  mounted() {},
});
