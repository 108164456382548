import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "out" }
const _hoisted_2 = { style: {"padding-left":"60px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Problem = _resolveComponent("Problem")!
  const _component_PlusOutlined = _resolveComponent("PlusOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_ProblemDrawer = _resolveComponent("ProblemDrawer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formState[_ctx.problemKey], (item, index) => {
        return (_openBlock(), _createBlock(_component_Problem, {
          key: _ctx.keyList[index],
          index: index,
          item: item,
          formState: _ctx.formState,
          "onUpdate:formState": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState) = $event)),
          problemKey: _ctx.problemKey,
          optionKey: _ctx.optionKey,
          taskPackageKey: _ctx.taskPackageKey,
          taskSubKey: _ctx.taskSubKey,
          frequency: _ctx.frequency,
          isMust: _ctx.isMust,
          isEdit: _ctx.isEdit && _ctx.computedIsEdit(index),
          type: _ctx.type,
          platform: _ctx.platform
        }, null, 8, ["index", "item", "formState", "problemKey", "optionKey", "taskPackageKey", "taskSubKey", "frequency", "isMust", "isEdit", "type", "platform"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isEdit)
        ? (_openBlock(), _createBlock(_component_a_button, {
            key: 0,
            ghost: "",
            type: "primary",
            onClick: _ctx.add
          }, {
            default: _withCtx(() => [
              _createVNode(_component_PlusOutlined, { style: {"font-size":"14px","position":"relative","top":"1px"} }),
              _createTextVNode(" 添加问题 ")
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_ProblemDrawer, {
      ref: "ProblemDrawer",
      formState: _ctx.formState,
      "onUpdate:formState": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState) = $event)),
      index: _ctx.itemIndex,
      "onUpdate:index": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.itemIndex) = $event)),
      item: _ctx.itemProblem,
      "onUpdate:item": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.itemProblem) = $event)),
      problemKey: _ctx.problemKey,
      optionKey: _ctx.optionKey,
      taskPackageKey: _ctx.taskPackageKey,
      taskSubKey: _ctx.taskSubKey,
      perDisabledIndex: _ctx.itemIndex,
      frequency: _ctx.frequency,
      isMust: _ctx.isMust,
      type: _ctx.type,
      platform: _ctx.platform
    }, null, 8, ["formState", "index", "item", "problemKey", "optionKey", "taskPackageKey", "taskSubKey", "perDisabledIndex", "frequency", "isMust", "type", "platform"])
  ]))
}