import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ee34e8d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  style: {"padding-top":"20px"},
  class: "box"
}
const _hoisted_2 = { style: {"padding":"0 6px 0 7px","font-size":"12px","color":"#aaa"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_CloseOutlined = _resolveComponent("CloseOutlined")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_PlusOutlined = _resolveComponent("PlusOutlined")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item[_ctx.optionKey], (i, index) => {
      return (_openBlock(), _createBlock(_component_a_form_item, {
        class: "option",
        label: " ",
        name: _ctx.optionKey,
        colon: false,
        key: _ctx.$store.state.config.letter[index],
        rules: _ctx.rulesOption(index)
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            style: {"width":"440px"},
            placeholder: "请输入...",
            value: _ctx.item[_ctx.optionKey][index].title,
            "onUpdate:value": ($event: any) => ((_ctx.item[_ctx.optionKey][index].title) = $event),
            valueModifiers: { trim: true },
            maxlength: 250,
            allowClear: "",
            size: "small",
            onChange: _ctx.change,
            autocomplete: "off"
          }, {
            prefix: _withCtx(() => [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$store.state.config.letter[index]) + "、", 1)
            ]),
            _: 2
          }, 1032, ["value", "onUpdate:value", "onChange"]),
          _createVNode(_component_CloseOutlined, {
            class: "delOption",
            onClick: ($event: any) => (_ctx.delOption(i, index)),
            style: {"color":"#aaa","font-size":"14px"}
          }, null, 8, ["onClick"])
        ]),
        _: 2
      }, 1032, ["name", "rules"]))
    }), 128)),
    _createVNode(_component_a_form_item, {
      label: " ",
      colon: false,
      name: "optionAdd",
      class: "optionAdd"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          style: {"color":"#008bcf","cursor":"pointer"},
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.add && _ctx.add(...args)))
        }, [
          _createVNode(_component_PlusOutlined, { style: {"font-size":"14px","position":"relative","top":"1px"} }),
          _createTextVNode(" 添加选项 ")
        ])
      ]),
      _: 1
    })
  ]))
}