
import { defineComponent } from "vue";
import { PlusOutlined } from "@ant-design/icons-vue";
import Problem from "@/components/task/carryOut/Problem.vue";
import DecorateModalTitle from "@/components/modification/DecorateModalTitle.vue";
import ProblemDrawer from "@/components/task/carryOut/ProblemDrawer.vue";
import { updateMode } from "@/api/task/api.js";
let keyList: any = [];
for (let i = 0; i < 1000; i++) {
  keyList.push(i);
}
export default defineComponent({
  components: {
    PlusOutlined,
    Problem,
    DecorateModalTitle,
    ProblemDrawer,
  },
  props: {
    formState: {
      type: Object,
      default: {},
    },
    platform: {
      type: Object,
      default: {},
    },
    //子任务执行频次 仅当拓店任务的时候固定传0
    frequency: {
      type: Number,
      default: 0,
    },
    //子任务是否必做
    isMust: {
      type: Number,
      default: 1,
    },
    //问题的键值
    problemKey: {
      type: String,
      default: "problem",
    },
    //选择题键值
    optionKey: {
      type: String,
      default: "option",
    },
    //子任务键值
    taskSubKey: {
      type: String,
      default: "taskSub",
    },
    //任务包键值
    taskPackageKey: {
      type: String,
      default: "taskPackage",
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      keyList: keyList,
      itemProblem: {
        [this.optionKey]: [ {}, {}, {}, {} ],
      },
      itemIndex: 0,
    };
  },
  methods: {
    resetItemProblem() {
      this.itemProblem = {
        [this.optionKey]: [ {}, {}, {}, {} ],
      };
      // this.itemProblem[this.optionKey] = [];
    },
    onPer() {},
    //新增子问题
    add() {
      if (!this.formState[this.problemKey]) {
        this.formState[this.problemKey] = [];
      }
      if (
        this.formState[this.problemKey].length <
        (this as any).$store.state.config.problemNum
      ) {
        this.resetItemProblem();
        this.itemIndex = this.formState[this.problemKey].length;
        (this as any).$refs.ProblemDrawer.show(this.itemProblem);
      } else {
        (this as any).$message.warning(
          `最多只能配置${(this as any).$store.state.config.problemNum}个问题`
        );
      }
    },
    configSub(title) {
      return new Promise((resolve, reject) => {
        let templateFormState = (this as any).$store.state.task.formState;
        let param = {
          taskInfoCode: templateFormState.taskInfo.taskInfoCode,
          [this.taskPackageKey]: {
            packageCode: templateFormState.taskPackage.packageCode || null,
            packageType: 1, //任务包类型:关联的任务类型,0：执行模板；1：执行任务，2：非执行任务，3：任务库 固定传1 ?暂定
            publishPort: (this as any).$store.state.task.checkPublishPort(
              templateFormState.taskInfo.taskTypeCode
            ),
            performPort: (this as any).$store.state.task.checkPerformPort(
              templateFormState.taskInfo.taskTypeCode
            ),
            [this.taskSubKey]: [
              {
                code:
                  templateFormState[this.taskPackageKey][this.taskSubKey][0]
                    .code || null,
                [this.problemKey]: [
                  {
                    options: [],
                    itemType: "text",
                    title: title,
                    isMust: 1,
                    charMaxCnt: "100",
                    tips: "",
                    status: 1,
                  },
                ],
                frequency: this.frequency, //子任务执行频次 拓店任务固定传0
                must: this.isMust, //子任务是否必做
              },
            ],
          },
        };
        updateMode(param).then((res) => {
          if (res.data.success) {
            (this as any).$store.state.task
              .getNewformState(this.$route.params.id)
              .then((res1) => {
                if (res1) {
                  (this as any).$store.commit("task/formState", res1); //如果新建包的时候需要更新任务包code  子任务code 单个新增采集项的code
                  resolve(true);
                }
              });
          }
        });
      });
    },
    //校验是否是需要初始化项目
    checkinitFlag() {
      let flag = false;
      let templateFormState = (this as any).$store.statetask.formState;
      if (
        !templateFormState.taskPackage ||
        !templateFormState.taskPackage.taskSub ||
        !templateFormState.taskPackage.taskSub[0] ||
        !templateFormState.taskPackage.taskSub[0].collectItems ||
        templateFormState.taskPackage.taskSub[0].collectItems.length <= 1
      ) {
        flag = true;
      }
      return flag;
    },
    init() {
      return new Promise((resolve, reject) => {
        // //针对新增店铺进行默认任务配置 taskTypeCode===CTT000ADDSTORE 且 没有taskPackage 或 taskPackage.taskSub[0].collectItems.length===0 时触发
        // let templateFormState = (this as any).$store.state.task.formState;
        // if (
        //   templateFormState.taskTypeCode === "CTT000ADDSTORE" &&
        //   this.checkinitFlag()
        // ) {
        //   this.configSub("客户名称").then((res) => {
        //     if (res) {
        //       this.configSub("客户地址").then((res2) => {
        //         if (res2) {
        //           resolve("update");
        //           this.$emit("init");
        //         }
        //       });
        //     }
        //   });
        // } else {
        //   resolve("notUpdate");
        //   this.$emit("init");
        // }
      });
    },
    computedIsEdit(index) {
      let templateFormState = (this as any).$store.state.task.formState;
      if (
        templateFormState.taskTypeCode === "CTT000ADDSTORE" &&
        (index === 0 || index === 1)
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {},
});
