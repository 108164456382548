import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-557f835c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "upload submit_upload" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "ant-upload-text" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 0,
  class: "control"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlusOutlined = _resolveComponent("PlusOutlined")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_EyeOutlined = _resolveComponent("EyeOutlined")!
  const _component_Edit = _resolveComponent("Edit")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_upload, {
      customRequest: _ctx.customRequest,
      "list-type": "picture-card",
      "file-list": _ctx.fileList,
      "onUpdate:fileList": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fileList) = $event)),
      accept: ".jpg,.jpeg,.png",
      beforeUpload: _ctx.beforeUpload
    }, {
      default: _withCtx(() => [
        (_ctx.fileList.length < 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_PlusOutlined, { style: {"font-size":"30px","color":"#999","margin-bottom":"5px"} }),
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.uploadTip), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["customRequest", "file-list", "beforeUpload"]),
    _createVNode(_component_a_modal, {
      visible: _ctx.previewVisible,
      footer: null,
      onCancel: _ctx.handleCancel,
      centered: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          alt: "example",
          style: {"width":"100%"},
          src: _ctx.previewImage
        }, null, 8, _hoisted_4)
      ]),
      _: 1
    }, 8, ["visible", "onCancel"]),
    (_ctx.showControl)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.controlPreview && _ctx.controlPreview(...args)))
          }, [
            _createVNode(_component_EyeOutlined, {
              style: {"color":"#fff"},
              class: "icon"
            }),
            _createTextVNode(" 查看 ")
          ]),
          _createElementVNode("div", {
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.edit && _ctx.edit(...args)))
          }, [
            _createVNode(_component_Edit, {
              style: {"color":"#fff"},
              class: "icon editIcon"
            }),
            _createTextVNode(" 编辑 ")
          ]),
          _createElementVNode("div", {
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.remove && _ctx.remove(...args)))
          }, [
            _createVNode(_component_DeleteOutlined, {
              style: {"color":"#fff"},
              class: "icon"
            }),
            _createTextVNode(" 删除 ")
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}