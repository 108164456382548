
import { defineComponent } from "vue";
import Icon from "@ant-design/icons-vue";
export default defineComponent({
  components: {
    Icon,
  },
  props: {
    style: {
      type: Object,
      default: { color: "#999" },
    },
  },
});
