
import { defineComponent } from "vue";
import { PlusOutlined } from "@ant-design/icons-vue";
export default defineComponent({
  components: {
    PlusOutlined,
  },
  props: {
    item: {
      type: Object,
      default: {},
    },
    optionKey: {
      type: String,
      default: "option",
    },
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
});
