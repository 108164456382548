<template>
  <div style="display: flex; justify-content: space-between; margin-top: 20px">
    <slot>
      <div>
        共<span style="margin: 0 10px">{{ total }}</span
        >条数据
      </div>
      <!-- 
            <span style="margin: 0 10px">/</span>已选择
          <span style="color: #008bcf; margin: 0 10px">{{
            selectedRowKeys.length
          }}</span>
          条
           -->
    </slot>
    <a-pagination
      show-size-changer
      show-quick-jumper
      :total="total"
      :page-size-options="pageSizeOptions"
      v-model:current="pageCurrent"
      v-model:pageSize="pageSize"
      @showSizeChange="onShowSizeChange"
      @change="change"
    />
  </div>
</template>
<script>
export default {
  props: {
    total: {
      type: Number,
      default: 0,
    },
    pageSizeOptions: {
      type: Array,
      default: ["10", "20", "50", "100"],
    },
    expand: {
      type: String,
      default: "",
    },
    cache: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      pageCurrent:
        Number(
          sessionStorage.getItem(
            this.$route.path.replace(/\//g, "") + this.expand + "Num"
          )
        ) || 1,
      pageSize:
        Number(
          sessionStorage.getItem(
            this.$route.path.replace(/\//g, "") + this.expand + "Size"
          )
        ) || 10,
    };
  },
  methods: {
    //更换每页显示数量
    onShowSizeChange(current, pageSize) {
      this.pageCurrent = 1;
      this.pageSize = pageSize;
      if (this.cache) {
        sessionStorage.setItem(
          this.$route.path.replace(/\//g, "") + this.expand + "Size",
          this.pageSize + ""
        );
      }
      this.$emit("changePagination", {
        type: "size",
        pageNum: this.pageCurrent,
        pageSize: this.pageSize,
      });
    },
    reset() {
      this.pageSize = Number(this.pageSizeOptions[0]);
      if (this.cache) {
        sessionStorage.setItem(
          this.$route.path.replace(/\//g, "") + this.expand + "Size",
          this.pageSize + ""
        );
      }
      this.pageCurrent = 1;
      if (this.cache) {
        sessionStorage.setItem(
          this.$route.path.replace(/\//g, "") + this.expand + "Num",
          this.pageCurrent + ""
        );
      }
    },
    change(page, pageSize) {
      if (this.cache) {
        sessionStorage.setItem(
          this.$route.path.replace(/\//g, "") + this.expand + "Num",
          this.pageCurrent + ""
        );
      }
      this.$emit("changePagination", {
        type: "num",
        pageNum: this.pageCurrent,
        pageSize: this.pageSize,
      });
    },
  },
  created() {
    this.$store.watch(
      (state, getters) => {
        return state.searchFlagAdd;
      },
      () => {
        this.reset();
      }
    );
  },
};
</script>
<style lang="scss">
.ant-pagination-options-size-changer.ant-select {
  width: 90px;
  text-align: center;
}
</style>
