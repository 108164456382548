
import { defineComponent, createVNode } from "vue";
import { Modal } from "ant-design-vue";
import {
  PlusOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import ProblemDrawer from "@/components/task/carryOut/ProblemDrawer.vue";
import Edit from "@/components/icon/Edit.vue";
import Del from "@/components/icon/Del.vue";
import { updateMode } from "@/api/task/api.js";
export default defineComponent({
  components: {
    PlusOutlined,
    CloseOutlined,
    ExclamationCircleOutlined,
    ProblemDrawer,
    Del,
    Edit,
  },
  props: {
    //全部数据结构
    formState: {
      type: Object,
      default: {},
    },
    //子任务执行频次 仅当拓店任务的时候固定传0
    frequency: {
      type: Number,
      default: 0,
    },
    //子任务是否必做
    isMust: {
      type: Number,
      default: 1,
    },
    //当前配置单元
    item: {
      type: Object,
      default: {},
    },
    //当前配置单元索引值
    index: {
      type: Number,
      default: 0,
    },
    //问题的键值
    problemKey: {
      type: String,
      default: "problem",
    },
    //选择题选项键值
    optionKey: {
      type: String,
      default: "option",
    },
    //子任务键值
    taskSubKey: {
      type: String,
      default: "taskSub",
    },
    //子任务的索引值 本期所有的子任务都只有一项所以固定0
    taskSubIndexKey: {
      type: Number,
      default: 0,
    },
    //任务包键值
    taskPackageKey: {
      type: String,
      default: "taskPackage",
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "",
    },
    platform: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {};
  },
  methods: {
    edit() {
      (this as any).$refs.ProblemDrawer.show(this.item);
    },
    del() {
      let that = this;
      Modal.confirm({
        title: "提示",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: "是否删除此问题",
        okText: "确认",
        cancelText: "取消",
        onOk() {
          // 此处先发请求
          let templateFormState = (that as any).$store.state.task.formState;
          let data = {
            taskInfoCode: templateFormState.taskInfo.taskInfoCode,
            // [that.taskPackageKey + "Code"]:
            //   templateFormState[that.taskPackageKey + "Code"] || null,
            [that.taskPackageKey]: {
              packageCode: templateFormState.taskPackage.packageCode || null,
              packageType: 1, //任务包类型:关联的任务类型,0：执行模板；1：执行任务，2：非执行任务，3：任务库 固定传1 ?暂定
              [that.taskSubKey]: [
                {
                  code:
                    templateFormState[that.taskPackageKey][that.taskSubKey][
                      that.taskSubIndexKey
                    ].code || null,
                  [that.problemKey]: [{ ...that.item, status: 0 }],
                  frequency: that.frequency, //子任务执行频次 拓店任务固定传0
                  must: that.isMust, //子任务是否必做
                },
              ],
            },
          };
          updateMode(data).then((res) => {
            if (res.data.success) {
              (that as any).$store.state.task
                .getNewformState(that.$route.params.id)
                .then((res1) => {
                  if (res1) {
                    (that as any).$store.commit("task/formState", res1); //如果新建包的时候需要更新任务包code  子任务code 单个新增采集项的code
                    (that as any).formState[that.problemKey].splice(
                      that.index,
                      1
                    ); //更新页面
                  }
                });
            }
          });
        },
        onCancel() {},
      });
    },
  },
  mounted() {},
});
