
import { defineComponent } from "vue";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons-vue";
export default defineComponent({
  components: {
    PlusOutlined,
    CloseOutlined,
  },
  props: {
    item: {
      type: Object,
      default: {},
    },
    optionKey: {
      type: String,
      default: "option",
    },
  },
  data() {
    return {
      letter: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "G"],
    };
  },
  methods: {
    change() {
      let that = (this as any).$store.state.task.vueProblemDrawer;
      if (that.$refs.formRef) {
        (that as any).$refs.formRef.validateFields(['options']);
      }
    },
    add() {
      if (!(this as any).item[this.optionKey]) {
        (this as any).item[this.optionKey] = [];
      }
      if ((this as any).item[this.optionKey].length < this.letter.length) {
        (this as any).item[this.optionKey].push({});
      } else {
        (this as any).$message.warning(`最多只支持${this.letter.length}个选项`);
      }
    },
    rulesOption(index) {
      return [
        {
          trigger: "blur",
          validator: async (rule, value, item) => {
            if (!value[index].title) {
              return Promise.reject("请输入");
            }
            let indexValue = null;
            let list2: any = value.map((el, i) => {
              if (i === index) {
                indexValue = el.title;
                return null;
              } else {
                return el.title;
              }
            });
            if (list2.includes(indexValue)) {
              return Promise.reject("选项不可重复");
            }
          },
        },
      ];
    },
    //删除选项
    delOption(item, index) {
      this.item[this.optionKey].splice(index, 1);
      this.$emit("delOption");
    },
  },
  mounted() {},
});
