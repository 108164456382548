
import { defineComponent } from "vue";
import DecorateModalTitle from "@/components/modification/DecorateModalTitle.vue";
import Date from "@/components/task/taskConfig/Date.vue";
import moment from "moment";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { taskConfig, taskRelease } from "@/api/task/api.js";
export default defineComponent({
  components: { DecorateModalTitle, Date, DeleteOutlined, PlusOutlined },
  props: {
    //传过来的数据 用于发布
    formState: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      data: {
        rulesType: 0, // 执行类型 0:一次性 1:周循环 2:时间段  当前只有0和1
        taskDayList: [
          {
            // startDay: null,
            // endDay: null,
            // times: null,
          },
        ],
      },
      visible: false,
      confirmLoading: false,
    };
  },
  methods: {
    async show() {
      //每次打开弹窗清空数据 否则会出现关闭弹窗修改任务执行时间导致日期禁用判断错误
      this.data = {
        rulesType: 0, // 执行类型 0:一次性 1:周循环 2:时间段  当前只有0和1
        taskDayList: [{}],
      };
      this.visible = true;
    },
    changeVal(val, index) {
      if (!(this as any).data.taskDayList[index].times) {
        (this as any).data.taskDayList[index].times = 1;
      }
    },
    changeNum(item) {
      if (item.times === null) {
        item.times = 1;
      }
    },
    ok() {
      this.confirmLoading = true;
      let param = {
        taskInfoCode: this.formState.taskInfoCode, // 任务编码
        taskStartDate: this.formState.planStartTime, // 任务开始执行时间(yyyy-MM-dd)
        taskEndDate: this.formState.planEndTime, // 任务结束执行时间(yyyy-MM-dd)
        rulesType: this.data.rulesType, // 执行类型 0:一次性 1:周循环 2:时间段
        taskDayList: [
          {
            startDay: null, // 开始日期(yyyy-MM-dd)
            endDay: null, // 结束日期(yyyy-MM-dd)
            times: 1, // 执行次数
          },
        ],
      };
      if (this.data.rulesType === 0) {
        param.taskDayList = [
          // 执行时间段配置(时间段配置)
          {
            startDay: this.formState.planStartTime.slice(0, 10), // 开始日期(yyyy-MM-dd)
            endDay: this.formState.planEndTime.slice(0, 10), // 结束日期(yyyy-MM-dd)
            times: 1, // 执行次数
          },
        ];
      } else {
        (param as any).taskDayList = this.data.taskDayList
          .filter((el: any) => el.startDay)
          .map((el: any) => {
            return {
              startDay: el.startDay.slice(0, 10),
              endDay: el.endDay.slice(0, 10),
              times: el.times,
            };
          });
      }
      taskRelease({
        taskCode: this.formState.taskInfoCode,
        status: 1, // 0编辑中，1已发布，2进行中，4已终止，5已结束
        rules: param,
      })
        .then((res) => {
          if (res.data.success) {
            (this as any).$message.success("发布成功");
            setTimeout(() => {
              this.visible = false;
              this.confirmLoading = false;
              this.$emit("configDone");
            }, 1000);
          } else {
            this.confirmLoading = false;
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        });
    },
    disabledok() {
      if (this.data.rulesType === 0) {
        return false;
      } else if (!(this as any).data.taskDayList[0].startDay) {
        return true;
      } else {
        return false;
      }
    },
    disabledDateCb(current, index) {
      if (!current) {
        return false;
      } else if (
        current <= moment().add(-1, "days") ||
        current < moment(this.formState.planStartTime) ||
        current > moment(this.formState.planEndTime)
      ) {
        //大于今天  大于等于任务开始时间  小于等于任务结束时间 可使用 相反的情况返回true禁用
        return true;
      } else if (
        index !== 0 &&
        current <
          moment((this as any).data.taskDayList[index - 1].endDay).add(
            1,
            "days"
          )
      ) {
        //上一个日期存在且小于等于上一个结束时间的 禁用
        return true;
      } else if (
        index !== (this as any).data.taskDayList.length - 1 &&
        (this as any).data.taskDayList[index + 1].startDay &&
        current >= moment((this as any).data.taskDayList[index + 1].startDay)
      ) {
        //下一个日期存在且大于上一个开始时间的 禁用
        return true;
      }
    },
    checkMax(item) {
      //最多只能一天一次
      return moment(item.endDay).diff(moment(item.startDay), "days") + 1;
    },
    add() {
      if (
        !(this as any).data.taskDayList[this.data.taskDayList.length - 1]
          .startDay
      ) {
        (this as any).$message.warning(`请先填写执行周期时间`);
      } else if (
        this.data.taskDayList.length <
        (this as any).$store.state.config.taskConfig.length
      ) {
        this.data.taskDayList.push({});
      } else {
        (this as any).$message.warning(
          `最多只能添加${
            (this as any).$store.state.config.taskConfig.length
          }个周期`
        );
      }
    },
    del(index) {
      if (this.data.taskDayList.length > 1) {
        this.data.taskDayList.splice(index, 1);
      } else {
        (this as any).$message.warning(`最少保留一个周期`);
      }
    },
  },
});
