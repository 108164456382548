
import { defineComponent } from "vue";
import DecorateModalTitle from "@/components/modification/DecorateModalTitle.vue";
import { listColumns } from "@/api/task/fixedData.js";
import {
  terminalList,
  getchannelOption,
  getcusLabIdOption,
  getactivityOption,
} from "@/api/task/api.js";
import Pagination from "@/components/list/Pagination.vue";
import Screen from "@/components/list/Screen.vue";
// import data from "@/mock/mock";
export default defineComponent({
  components: { DecorateModalTitle, Pagination, Screen },
  props: {
    selected: {
      type: Object,
      default: {},
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      formState: { activity: null, channelCode: null, cusLabId: null },
      keepFormState: { activity: null, channelCode: null, cusLabId: null },
      dataAll: [],
      columns: listColumns,
      //总数据量
      total: 0,
      //table的布局
      scroll: { x: "100%", y: 380 },
      //批量操作按钮加载
      loading: false,
      visible: false,
      selectedRowKeys: [], //全部被选中的key值
      selectedData: [], //全部被选中的全部数据
      confirmLoading: false,
      channelOption: [],
      cusLabIdOption: [],
      activityOption: [],
    };
  },
  methods: {
    //复选结果
    onSelectChange(selectedRowKeys: any) {
      this.selectedRowKeys = selectedRowKeys;
    },
    onSelectAll(selected, record, selectedRows) {
      selectedRows.forEach((element) => {
        this.onSelect(element, selected);
      });
    },
    onSelect(record, selected) {
      if (selected) {
        (this as any).selectedData.push(record);
      } else {
        this.selectedData = this.selectedData.filter(
          (el: any) => el.terminalCode !== record.terminalCode
        );
      }
    },
    getData(pageSize = 10, pageNum = 1) {
      this.loading = true;
      terminalList({
        activity: (this as any).formState.activity,
        cusLabId: (this as any).formState.cusLabId,
        channelCode: (this as any).formState.channelCode,
        keyword: (this as any).formState.keyword,
        person: (this as any).formState.person,
        pageSize: pageSize,
        pageNum: pageNum,
      }).then((res: any) => {
        // res.data.data.content = data["data" + pageNum]; //假数据
        // res.data.data.total = 21; //假数据
        this.dataAll = res.data.data.content;
        this.total = res.data.data.total;
        this.loading = false;
      });
    },
    changePagination(page) {
      this.getData(page.pageSize, page.pageNum);
    },
    async show(selectedData) {
      this.selectedData = JSON.parse(JSON.stringify(selectedData));
      this.selectedRowKeys = selectedData.map((el) => el.terminalCode); //后端若传全部则打开此项
      this.visible = true;
      setTimeout(() => {
        this.getData(
          (this as any).$refs.Pagination.pageSize,
          (this as any).$refs.Pagination.pageCurrent
        );
      }, 0);
    },
    search() {
      (this as any).$refs.Pagination.pageSize = 10;
      (this as any).$refs.Pagination.pageCurrent = 1;
      this.getData();
    },
    ok() {
      // console.log(this.selectedData);
      this.confirmLoading = true;
      this.$emit("sub", this.selectedData);
      this.visible = false;
      this.confirmLoading = false;
    },
    async getOption(type) {
      let res;
      switch (type) {
        case "channelOption":
          res = await getchannelOption();
          res.data.data = [
            { channelCode: null, channelName: "全部" },
            ...res.data.data,
            { channelCode: "未知", channelName: "未知" },
          ];
          break;
        case "cusLabIdOption":
          res = await getcusLabIdOption();
          res.data.data = [
            { customerLabelCode: null, customerLabelName: "全部" },
            ...res.data.data,
          ];
          break;
        case "activityOption":
          res = await getactivityOption();
          res.data.data = [{ code: null, name: "全部" }, ...res.data.data];
          break;
      }
      this[type] = res.data.data;
    },
  },
  created() {
    if (this.isEdit) {
      this.getOption("channelOption");
      this.getOption("cusLabIdOption");
      this.getOption("activityOption");
    }
  },
});
