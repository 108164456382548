<template>
  <div class="searchBox">
    <a-button type="primary" @click="search"
      ><SearchOutlined
        style="font-size: 14px; position: relative; top: 1px"
      />搜索</a-button
    >
    <a-button
      style="margin-left: 10px; border-color: #008bcf; color: #008bcf"
      @click="reset"
      ><SyncOutlined
        style="font-size: 14px; position: relative; top: 1px"
      />重置</a-button
    >
  </div>
</template>
<script>
import { SyncOutlined, SearchOutlined } from "@ant-design/icons-vue";
import Sub from "@/components/list/Sub.vue";
export default {
  props: {
    top: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SyncOutlined,
    SearchOutlined,
    Sub,
  },
  methods: {
    search() {
      this.$store.commit("searchFlagAdd");
      this.$emit("search");
    },
    reset() {
      this.$emit("reset");
    },
  },
};
</script>