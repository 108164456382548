import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_form_item = _resolveComponent("a-form-item")!

  return (_openBlock(), _createBlock(_component_a_form_item, {
    label: _ctx.label,
    name: "date",
    rules: _ctx.rules
  }, {
    default: _withCtx(() => [
      (_ctx.isEdit)
        ? (_openBlock(), _createBlock(_component_a_range_picker, {
            key: 0,
            value: _ctx.formState.date,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.date) = $event)),
            style: {"width":"100%"},
            valueFormat: "YYYY-MM-DD",
            "disabled-date": _ctx.disabledDate,
            onChange: _ctx.change,
            size: _ctx.size
          }, null, 8, ["value", "disabled-date", "onChange", "size"]))
        : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.start && _ctx.start.slice(0, 10)) + " ~ " + _toDisplayString(_ctx.end && _ctx.end.slice(0, 10)), 1))
    ]),
    _: 1
  }, 8, ["label", "rules"]))
}