import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62435252"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "required"
}
const _hoisted_2 = { class: "index" }
const _hoisted_3 = { class: "titleProblem" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { key: 3 }
const _hoisted_8 = { key: 4 }
const _hoisted_9 = { key: 5 }
const _hoisted_10 = { key: 6 }
const _hoisted_11 = { key: 7 }
const _hoisted_12 = {
  key: 1,
  class: "contentProblem"
}
const _hoisted_13 = { class: "letter" }
const _hoisted_14 = {
  key: 2,
  class: "contentProblem"
}
const _hoisted_15 = { class: "edit" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_image = _resolveComponent("a-image")!
  const _component_Edit = _resolveComponent("Edit")!
  const _component_Del = _resolveComponent("Del")!
  const _component_ProblemDrawer = _resolveComponent("ProblemDrawer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["box", _ctx.isEdit ? 'hoverBox' : ''])
    }, [
      (_ctx.item.isMust)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, "✶"))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.index + 1) + "、", 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", null, [
          _createTextVNode("【 "),
          (_ctx.item.itemType == 'radio')
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, "单选题"))
            : _createCommentVNode("", true),
          (_ctx.item.itemType == 'checkbox')
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, "多选题"))
            : _createCommentVNode("", true),
          (_ctx.item.itemType == 'picture')
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, "图片收集单张"))
            : _createCommentVNode("", true),
          (_ctx.item.itemType == 'multiplepictures')
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, "图片收集多张"))
            : _createCommentVNode("", true),
          (_ctx.item.itemType == 'text')
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, "问答题"))
            : _createCommentVNode("", true),
          (_ctx.item.itemType == 'number')
            ? (_openBlock(), _createElementBlock("span", _hoisted_9, "数量收集"))
            : _createCommentVNode("", true),
          (_ctx.item.itemType == 'money')
            ? (_openBlock(), _createElementBlock("span", _hoisted_10, "金额收集"))
            : _createCommentVNode("", true),
          (_ctx.item.itemType == 'telephone')
            ? (_openBlock(), _createElementBlock("span", _hoisted_11, "电话收集"))
            : _createCommentVNode("", true),
          _createTextVNode(" 】")
        ]),
        _createElementVNode("span", null, _toDisplayString(_ctx.item.title), 1)
      ]),
      (_ctx.item.itemType == 'radio' || _ctx.item.itemType == 'checkbox')
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item[_ctx.optionKey], (i, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: _ctx.$store.state.config.letter[index],
                class: "option"
              }, [
                _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$store.state.config.letter[index]) + "、", 1),
                _createElementVNode("div", null, _toDisplayString(i.title), 1)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.item.itemType == 'picture' || _ctx.item.itemType == 'multiplepictures')
        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
            (_ctx.item.sampleImg)
              ? (_openBlock(), _createBlock(_component_a_image, {
                  key: 0,
                  style: {"width":"240px","height":"140px","object-fit":"cover","border-radius":"4px","cursor":"pointer"},
                  src: _ctx.item.sampleImgUrl
                }, null, 8, ["src"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_component_Edit, {
          style: {"cursor":"pointer","color":"#008bcf","margin-right":"20px"},
          onClick: _ctx.edit
        }, null, 8, ["onClick"]),
        _createVNode(_component_Del, {
          onClick: _ctx.del,
          style: {"cursor":"pointer","color":"#fe5052"}
        }, null, 8, ["onClick"])
      ])
    ], 2),
    _createVNode(_component_ProblemDrawer, {
      ref: "ProblemDrawer",
      formState: _ctx.formState,
      "onUpdate:formState": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState) = $event)),
      index: _ctx.index,
      "onUpdate:index": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.index) = $event)),
      item: _ctx.item,
      "onUpdate:item": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.item) = $event)),
      problemKey: _ctx.problemKey,
      optionKey: _ctx.optionKey,
      taskPackageKey: _ctx.taskPackageKey,
      taskSubKey: _ctx.taskSubKey,
      taskSubIndexKey: 0,
      frequency: _ctx.frequency,
      isMust: _ctx.isMust,
      nextDisabledIndex: _ctx.formState[_ctx.problemKey].length - 1,
      type: _ctx.type,
      platform: _ctx.platform,
      disableType: true
    }, null, 8, ["formState", "index", "item", "problemKey", "optionKey", "taskPackageKey", "taskSubKey", "frequency", "isMust", "nextDisabledIndex", "type", "platform"])
  ]))
}