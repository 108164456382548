import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_Date = _resolveComponent("Date")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_form_item, {
      label: "任务名称",
      name: "taskInfoTitle",
      rules: _ctx.rules.taskInfoTitle
    }, {
      default: _withCtx(() => [
        (_ctx.isEdit)
          ? (_openBlock(), _createBlock(_component_a_input, {
              key: 0,
              value: _ctx.formState.taskInfoTitle,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.taskInfoTitle) = $event)),
              valueModifiers: { trim: true },
              placeholder: "请输入",
              maxlength: 20,
              allowClear: "",
              readonly: !_ctx.isEdit
            }, null, 8, ["value", "readonly"]))
          : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.formState.taskInfoTitle), 1))
      ]),
      _: 1
    }, 8, ["rules"]),
    _createVNode(_component_a_form_item, {
      label: "任务编码",
      name: "taskInfoCode"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.formState.taskInfoCode ? _ctx.formState.taskInfoCode : "自动关联"), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_form_item, {
      label: "任务类型",
      name: "typeCode",
      rules: _ctx.rules.typeCode
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$store.state.config.taskType[_ctx.formState.typeCode]) + " ", 1)
      ]),
      _: 1
    }, 8, ["rules"]),
    _createVNode(_component_a_form_item, {
      label: "审核需求",
      name: "performAudit",
      rules: _ctx.rules.performAudit
    }, {
      default: _withCtx(() => [
        (_ctx.isEdit)
          ? (_openBlock(), _createBlock(_component_a_radio_group, {
              key: 0,
              name: "radioGroup",
              value: _ctx.formState.performAudit,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.performAudit) = $event))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_radio, {
                  value: 1,
                  style: {"margin-right":"60px"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("需要审核")
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_radio, { value: 0 }, {
                  default: _withCtx(() => [
                    _createTextVNode("无需审核")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["value"]))
          : (_openBlock(), _createElementBlock("span", _hoisted_2, [
              (_ctx.formState.performAudit == 1)
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, "需要审核"))
                : _createCommentVNode("", true),
              (_ctx.formState.performAudit == 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_4, "无需审核"))
                : _createCommentVNode("", true)
            ]))
      ]),
      _: 1
    }, 8, ["rules"]),
    _createVNode(_component_Date, {
      start: _ctx.formState.planStartTime,
      "onUpdate:start": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.planStartTime) = $event)),
      end: _ctx.formState.planEndTime,
      "onUpdate:end": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.planEndTime) = $event)),
      rules: _ctx.rules.date,
      disabledDateCb: _ctx.disabledDateCb,
      size: "large",
      isEdit: _ctx.isEdit
    }, null, 8, ["start", "end", "rules", "disabledDateCb", "isEdit"])
  ]))
}