
import { defineComponent } from "vue";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { listColumns } from "@/api/task/fixedData.js";
import Pagination from "@/components/list/Pagination.vue";
import Modal from "@/components/task/target/Modal.vue";
export default defineComponent({
  components: { DeleteOutlined, PlusOutlined, Pagination, Modal },
  props: {
    formState: {
      type: Object,
      default: {},
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dataPage: [],
      dataSelected: [],
      columns: [
        ...listColumns,
        {
          title: "操作",
          dataIndex: "handle",
          slots: { customRender: "handle" },
          fixed: "right",
          width: 100,
        },
      ],
      //总数据量
      total: 0,
      //table的布局
      scroll: { x: "100%", y: "calc(100vh - 330px)" },
      //批量操作按钮加载
      loading: false,
    };
  },
  methods: {
    getData(pageSize = 10, pageNum = 1) {
      return new Promise((resolve, reject) => {
        this.loading = true;
        // 不发请求  假分页
        this.dataPage = this.dataSelected.slice(
          pageNum * pageSize - pageSize,
          pageNum * pageSize
        );
        if (
          this.dataPage.length === 0 &&
          (this as any).$refs.Pagination.pageCurrent !== 1
        ) {
          (this as any).$refs.Pagination.pageCurrent--;
          this.dataPage = this.dataSelected.slice(
            (pageNum - 1) * pageSize - pageSize,
            (pageNum - 1) * pageSize
          );
        }
        this.total = this.dataSelected.length;
        this.loading = false;
        resolve(1);
      });
    },
    getDataSelected(dataSelected) {
      //获取全部不分页的被选中数据
      this.dataSelected = dataSelected;
    },
    changePagination(page) {
      this.getData(page.pageSize, page.pageNum);
    },
    add() {
      (this as any).$refs.Modal.show(this.dataSelected);
    },
    del(record) {
      this.dataSelected = this.dataSelected.filter(
        (el: any) => el.terminalCode != record.terminalCode
      );
      this.getData(
        (this as any).$refs.Pagination.pageSize,
        (this as any).$refs.Pagination.pageCurrent
      );
    },
    subModal(dataSelected) {
      this.dataSelected = dataSelected;
      (this as any).$refs.Pagination.pageSize = 10;
      (this as any).$refs.Pagination.pageCurrent = 1;
      this.getData();
    },
  },
});
