
import { defineComponent } from "vue";
import {
  PlusOutlined,
  EyeOutlined,
  DeleteOutlined,
} from "@ant-design/icons-vue";
import Edit from "@/components/icon/Edit.vue";
import { imageUpload } from "@/api/api.js";
function getBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default defineComponent({
  components: {
    Edit,
    PlusOutlined,
    EyeOutlined,
    DeleteOutlined,
  },
  props: {
    absoluteUrl: {
      type: String,
      default: "",
    },
    relativeUrl: {
      type: String,
      default: "",
    },
    uploadTip: {
      type: String,
      default: "点击上传示例",
    },
  },
  data() {
    return {
      fileList: [],
      previewVisible: false,
      previewImage: this.url,
      showControl: false,
    };
  },
  methods: {
    // handleChange({ fileList: newFileList }) {
    //   this.fileList = newFileList;
    //   //   this.showControl = true;
    // },
    beforeUpload(file, fileList) {
      //检验图片格式
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        (this as any).$message.error("上传图片应为PNG或JPG格式,请重新上传", 4);
        fileList.length = 0;
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        (this as any).$message.error("图片大小应小于5M,请重新上传", 4);
        fileList.length = 0;
      }

      return isJpgOrPng && isLt5M;
    },
    async controlPreview() {
      let file: any = this.fileList[0];
      if (!file.url && !file.preview) {
        file.preview = (await getBase64(file.originFileObj)) as string;
      }
      this.previewImage = this.absoluteUrl || file.url || file.preview;
      this.previewVisible = true;
    },
    remove() {
      this.fileList.length = 0;
      this.showControl = false;
      this.$emit("update:relativeUrl", null);
      this.$emit("update:absoluteUrl", null);
    },
    async customRequest(file) {
      const formData = new FormData();
      let name = file.file.name;
      name = name.substring(name.lastIndexOf(".") + 1);
      formData.append("file", file.file);
      formData.append("module", `template`);
      formData.append("scene", `sampleImg`);
      formData.append("format", name);
      let res: any = await imageUpload(formData, (progressEvent) => {
        let percent = ((progressEvent.loaded / progressEvent.total) * 99) | 0;
        file.onProgress({ percent: percent }); //调用uploader的进度回调
      }).catch(() => {
        setTimeout(() => {
          this.fileList = [];
        }, 300);
      });
      if (res.data.success) {
        file.onSuccess(res, file);
        this.showControl = true;
        (this as any).fileList[0].url = res.data.data.absoluteFileUrl;
        // this.previewImage = res.data.data.absoluteFileUrl;
        this.$emit("update:relativeUrl", res.data.data.fileUrl);
        this.$emit("update:absoluteUrl", res.data.data.absoluteFileUrl);
      } else {
        setTimeout(() => {
          this.fileList = [];
        }, 300);
      }
    },
    handleCancel() {
      this.previewVisible = false;
    },
    edit() {
      this.remove();
      setTimeout(() => {
        let upload: any = document.querySelector(
          ".ant-upload.ant-upload-select-picture-card > .ant-upload"
        );
        if (upload) {
          upload.click();
        }
      }, 0);
    },
  },
  watch: {
    absoluteUrl() {
      if (this.absoluteUrl) {
        (this as any).fileList = [{ url: this.absoluteUrl, uid: "-1" }];
        this.showControl = true;
      } else {
        (this as any).fileList = [];
        this.showControl = false;
      }
    },
  },
  mounted() {
    if (this.absoluteUrl) {
      (this as any).fileList = [{ url: this.absoluteUrl, uid: "-1" }];
      this.showControl = true;
    } else {
    }
  },
});
