// 终端 终端列表的固定表格第一行
export const listColumns = [
  {
    title: "#",
    slots: { customRender: "serialNumber" },
    width: 70,
    align: "center",
    fixed: "left",
  },
  {
    title: "客户名称/编码",
    dataIndex: "terminalName",
    slots: { customRender: "terminalName" },
    fixed: "left",
    width: 200,
    ellipsis: true,
  },
  {
    title: "渠道",
    dataIndex: "channelName",
    slots: { customRender: "channelName" },
    width: 100,
    ellipsis: true,
  },
  {
    title: "活跃度",
    dataIndex: "activity",
    slots: { customRender: "activity" },
    width: 100,
    ellipsis: true,
  },
  {
    title: "业务负责人",
    dataIndex: "person",
    slots: { customRender: "person" },
    width: 100,
    ellipsis: true,
  },
  {
    title: "客户标签",
    dataIndex: "cusLabels",
    slots: { customRender: "cusLabels" },
    width: 140,
    ellipsis: true,
  },
  {
    title: "详细地址",
    dataIndex: "address",
    slots: { customRender: "address" },
    width: 200,
    ellipsis: true,
  },
];
