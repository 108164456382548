
import { defineComponent, createVNode } from "vue";
import Base from "@/components/task/Base.vue";
import CarryOut from "@/components/task/CarryOut.vue";
import Target from "@/components/task/Target.vue";
import TaskConfig from "@/components/task/TaskConfig.vue";
import {
  getDetails,
  addMode,
  updateMode1,
  copyMode,
  addDetails,
} from "@/api/task/api.js";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import moment from "moment";
// import { Modal } from "ant-design-vue";
export default defineComponent({
  components: {
    Base,
    CarryOut,
    Target,
    TaskConfig,
    ExclamationCircleOutlined,
  },
  data() {
    return {
      spinning: false,
      current: 0, //当前步骤条
      currentDone: 0, //已完成到第几步
      steps: [
        {
          title: "基础信息",
          num: 0,
        },
        {
          title: "选择目标客户",
          num: 1,
        },
        {
          title: "执行配置",
          num: 2,
        },
      ],
      //从路由传过来的id获取到的本条数据
      formState: {
        taskDetails: [],
        taskInfo: {
          typeCode: "CTT000ORDINARY",
        },
        taskPackage: {
          taskSub: [{}],
        },
      },
      keepFormStateTaskInfo: JSON.stringify({
        // taskDetails: [],
        taskInfo: {},
        // taskPackage: {
        //   taskSub: [{}],
        // },
      }),
      keepTargetDataSelected: "",
    };
  },
  methods: {
    async getData(taskInfoCode: any = false, cb: any = false) {
      if (!taskInfoCode) {
        taskInfoCode = this.$route.params.id;
      }
      this.spinning = true;
      let res: any = await getDetails(taskInfoCode).catch(() => {
        this.spinning = false;
      });
      this.spinning = false;
      if (!res.data.data.taskPackage) {
        res.data.data.taskPackage = {};
        res.data.data.taskPackage.taskSub = [{}];
      }
      if (!res.data.data.taskDetails) {
        res.data.data.taskDetails = [];
      }
      if (!res.data.data.taskInfo) {
        res.data.data.taskInfo = {};
      }
      this.formState = res.data.data;
      this.keepFormStateTaskInfo = JSON.stringify(res.data.data.taskInfo);
      (this as any).$store.commit("task/formState", this.formState);
      //判断currentDone
      if ((this as any).$route.query.isEdit != 1) {
        this.currentDone = 2;
      } else if (
        this.formState.taskDetails &&
        this.formState.taskDetails.length > 0
      ) {
        this.currentDone = 2;
      } else {
        this.currentDone = 1;
      }
      if (cb) {
        cb();
      }
    },
    // 点击完成
    async saveStep0(next = true) {
      (this as any).$refs.formRef.validate().then(() => {
        //没有数据变更不走保存接口
        if (
          this.keepFormStateTaskInfo ===
            JSON.stringify(this.formState.taskInfo) &&
          next
        ) {
          if (this.current === this.currentDone) {
            this.currentDone++;
          }
          this.current++;
          return;
        }
        if (this.$route.params.id === "new") {
          // 新建 需创建任务包
          addMode({
            typeName: (this as any).$store.state.config.taskType[
              (this as any).formState.taskInfo.typeCode
            ],
            typeCode: (this as any).formState.taskInfo.typeCode,
            taskInfoTitle: (this as any).formState.taskInfo.taskInfoTitle,
            performAudit: (this as any).formState.taskInfo.performAudit,
            planStartTime: (this as any).formState.taskInfo.planStartTime,
            planEndTime: (this as any).formState.taskInfo.planEndTime,
          }).then((res) => {
            if (res.data.success) {
              this.keepFormStateTaskInfo = JSON.stringify(
                this.formState.taskInfo
              ); //更新缓存数据
              (this as any).$message.success("保存成功");
              this.$router.push({
                path: `/taskApp/pxt/created/${res.data.data.taskInfoCode}`,
                query: { ...this.$route.query },
              });
              setTimeout(() => {
                this.getData(this.$route.params.id, () => {
                  if (this.current === this.currentDone) {
                    this.currentDone++;
                  }
                  if (next) {
                    this.current++;
                  }
                });
              }, 0);
            }
          });
        } else {
          // 编辑
          // CTT000EXPANDTASK 拓店任务
          (this as any).formState.taskInfo.typeName = (
            this as any
          ).$store.state.config.taskType[
            (this as any).formState.taskInfo.typeCode
          ];
          updateMode1({
            taskInfoCode: (this as any).formState.taskInfo.taskInfoCode,
            typeName: (this as any).$store.state.config.taskType[
              (this as any).formState.taskInfo.typeCode
            ],
            typeCode: (this as any).formState.taskInfo.typeCode,
            taskInfoTitle: (this as any).formState.taskInfo.taskInfoTitle,
            performAudit: (this as any).formState.taskInfo.performAudit,
            planStartTime: (this as any).formState.taskInfo.planStartTime,
            planEndTime: (this as any).formState.taskInfo.planEndTime,
          }).then((res) => {
            if (res.data.success) {
              this.keepFormStateTaskInfo = JSON.stringify(
                this.formState.taskInfo
              ); //更新缓存数据
              (this as any).$message.success("保存成功");
              if (this.current === this.currentDone) {
                this.currentDone++;
              }
              if (next) {
                this.current++;
              }
            }
          });
        }
      });
    },
    async saveStep1(type) {
      // console.log((this as any).$refs.Target.dataSelected);
      // 先校验是否有配置终端 然后直接;
      if ((this as any).$refs.Target.dataSelected.length === 0) {
        (this as any).$message.warning("请先添加目标客户");
        return;
      }
      //没有数据变更不走保存接口
      if (
        this.keepTargetDataSelected ===
          JSON.stringify(this.getTargetDataSelected()) &&
        type === "next"
      ) {
        if (this.current === this.currentDone) {
          this.currentDone++;
        }
        this.current++;
        return;
      }
      let res = await addDetails({
        taskInfoCode: (this as any).formState.taskInfo.taskInfoCode,
        typeCode: (this as any).formState.taskInfo.typeCode,
        taskDetails: (this as any).$refs.Target.dataSelected,
      });
      if (res.data.success) {
        (this as any).$message.success("保存成功");
        this.keepTargetDataSelected = JSON.stringify(
          (this as any).$refs.Target.dataSelected
        ); //更新缓存数据
        this.getData();
        if (this.current === this.currentDone) {
          this.currentDone++;
        }
        if (type === "next") {
          this.current++;
        }
      }
    },
    async saveStep2(type) {
      // 校验结束时间不能早于当前时间
      let diff1 = moment(new Date()).diff(
        moment((this as any).formState.taskInfo.planStartTime),
        "days"
      );
      let diff2 = moment(new Date()).diff(
        moment((this as any).formState.taskInfo.planEndTime),
        "days"
      );
      // console.log(diff1, diff2);
      if (diff1 > 0) {
        (this as any).$message.warning(
          '步骤1:"基础信息"中的任务执行开始时间不能早于当前时间'
        );
        return;
      }
      if (diff2 > 0) {
        (this as any).$message.warning(
          '步骤1:"基础信息"中的任务执行结束时间不能早于当前时间'
        );
        return;
      }
      // 校验是否有考题
      if (
        !(this as any).formState.taskPackage.taskSub[0].collectItems ||
        (this as any).formState.taskPackage.taskSub[0].collectItems.length === 0
      ) {
        (this as any).$message.warning("请先添加问题");
        return;
      }
      if (type === "publish") {
        (this as any).$refs.TaskConfig.show();
      } else {
        this.cancel();
      }
    },
    //执行配置完成
    configDone() {
      this.cancel();
    },
    cancel() {
      (parent as any).urlCallback();
    },
    getTargetDataSelected() {
      if (this.$refs.Target) {
        return (this as any).$refs.Target.dataSelected;
      } else {
        return [];
      }
    },
  },
  mounted() {
    (this as any).$store.commit("task/cbUrl", this.$route.query.cbUrl);
    if (this.$route.query.type === "copy") {
      copyMode(this.$route.params.id).then((res: any) => {
        if (res.data.success) {
          this.formState = res.data.data;
          this.keepFormStateTaskInfo = JSON.stringify(res.data.data.taskInfo);
          (this as any).$store.commit("task/formState", this.formState);
          this.currentDone = 0;
          this.current = 0;
          this.$router.push({
            path: `/taskApp/pxt/created/${res.data.data.taskInfo.taskInfoCode}`,
            query: { ...this.$route.query },
          });
          setTimeout(() => {
            this.getData();
          }, 0);
        }
      });
    } else {
      if (this.$route.params.id != "new") {
        this.getData();
      } else {
        (this as any).$store.commit("task/formState", this.formState);
      }
    }
  },
  watch: {
    current(val) {
      // if (val === 2) {
      //   this.spinning = true;
      //   setTimeout(() => {
      //     (this as any).$refs.CarryOut.init().then((res) => {
      //       if (res === "notUpdate") {
      //         this.spinning = false;
      //       }
      //       if (res === "update") {
      //         this.getData(this.$route.params.id, () => {
      //           this.spinning = false;
      //         });
      //       }
      //     });
      //   }, 0);
      // }
      if (val === 1) {
        this.spinning = true;
        setTimeout(() => {
          //此处传入全部被选中的项
          (this as any).$refs.Target.getDataSelected(
            this.formState.taskDetails
          );
          //再进行假分页
          (this as any).$refs.Target.getData().then(() => {
            this.spinning = false;
          });
          this.keepTargetDataSelected = JSON.stringify(
            (this as any).$refs.Target.dataSelected
          ); //设置初次缓存数据
        }, 0);
      }
    },
  },
});
