
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    start: {
      type: String,
      default: null,
    },
    end: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: "执行时间",
    },
    size: {
      type: String,
      default: "default",
    },
    index: {
      type: Number,
      default: 0,
    },
    rules: {
      type: Array,
      default: [
        {
          required: true,
          trigger: "blur",
          validator: async (rule, value) => {
            if (!1) {
              return Promise.reject(`未传入校验规则`);
            }
          },
        },
      ],
    },
    disabledDateCb: {
      type: Function,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      formState: {
        date: [this.start, this.end],
      },
    };
  },
  methods: {
    disabledDate(current) {
      return (this as any).disabledDateCb(current, (this as any).index);
    },
    init() {
      (this as any).formState.date = [(this as any).start, (this as any).end];
    },
    change(value) {
      this.$emit("changeVal", value, (this as any).index);
      this.$emit("update:start", this.formState.date[0]);
      this.$emit("update:end", this.formState.date[1]);
    },
  },
  watch: {
    start() {
      this.init();
    },
    end() {
      this.init();
    },
  },
});
