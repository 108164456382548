import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56c2e6fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"background-color":"#ededed"} }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "stepsBox flexCenter" }
const _hoisted_4 = { style: {"width":"680px"} }
const _hoisted_5 = {
  class: "flex",
  style: {"flex-direction":"column","align-items":"center"}
}
const _hoisted_6 = {
  key: 0,
  style: {"width":"100%"}
}
const _hoisted_7 = {
  key: 0,
  class: "submitBox"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_step = _resolveComponent("a-step")!
  const _component_a_steps = _resolveComponent("a-steps")!
  const _component_Base = _resolveComponent("Base")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_Target = _resolveComponent("Target")!
  const _component_CarryOut = _resolveComponent("CarryOut")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_TaskConfig = _resolveComponent("TaskConfig")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createBlock(_component_a_spin, {
    spinning: _ctx.spinning || _ctx.$store.state.loadingStatus
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.$route.query.checkUrl == 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$route.href), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_a_steps, {
              current: _ctx.current,
              "onUpdate:current": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.current) = $event)),
              class: "steps"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (item) => {
                  return (_openBlock(), _createBlock(_component_a_step, {
                    key: item.title,
                    title: item.title,
                    disabled: _ctx.$route.query.isEdit == 1
                  }, null, 8, ["title", "disabled"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["current"])
          ])
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(
          !_ctx.$route.query.styletype
            ? 'styletype0' + ' ' + (_ctx.$route.query.isEdit != 1 ? 'noBottom' : '')
            : ''
        )
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["contentBox", 
            _ctx.$route.query.styletype
              ? _ctx.$route.query.styletype +
                ' ' +
                (_ctx.$route.query.isEdit != 1 ? 'noBottom' : '')
              : ''
          ])
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_a_form, {
                model: _ctx.formState.taskInfo,
                ref: "formRef",
                "label-col": { style: { width: '110px' } }
              }, {
                default: _withCtx(() => [
                  (_ctx.current === 0)
                    ? (_openBlock(), _createBlock(_component_Base, {
                        key: 0,
                        formState: _ctx.formState.taskInfo,
                        isEdit: _ctx.$route.query.isEdit == 1
                      }, null, 8, ["formState", "isEdit"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["model"]),
              (_ctx.current === 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_component_Target, {
                      formState: _ctx.formState.taskDetails,
                      ref: "Target",
                      isEdit: _ctx.$route.query.isEdit == 1
                    }, null, 8, ["formState", "isEdit"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.current === 2)
                ? (_openBlock(), _createBlock(_component_CarryOut, {
                    key: 1,
                    style: {"width":"50%"},
                    formState: _ctx.formState.taskPackage.taskSub[0],
                    problemKey: "collectItems",
                    optionKey: "options",
                    taskPackageKey: "taskPackage",
                    taskSubKey: "taskSub",
                    frequency: 0,
                    isMust: 0,
                    isEdit: _ctx.$route.query.isEdit == 1,
                    ref: "CarryOut"
                  }, null, 8, ["formState", "isEdit"]))
                : _createCommentVNode("", true)
            ])
          ], 2),
          (_ctx.$route.query.isEdit == 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_ctx.current === 0)
                  ? (_openBlock(), _createBlock(_component_a_button, {
                      key: 0,
                      style: {"margin-right":"12px","height":"32px"},
                      onClick: _ctx.cancel
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" 返回 ")
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (_ctx.current !== 0)
                  ? (_openBlock(), _createBlock(_component_a_button, {
                      key: 1,
                      style: {"margin-right":"12px","height":"32px"},
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.current--))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" 上一步 ")
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.current === 1)
                  ? (_openBlock(), _createBlock(_component_a_button, {
                      key: 2,
                      type: "primary",
                      style: {"margin-right":"12px","height":"32px"},
                      onClick: _ctx.saveStep1,
                      disabled: 
              _ctx.keepTargetDataSelected ===
              JSON.stringify(this.getTargetDataSelected())
            
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" 保存 ")
                      ]),
                      _: 1
                    }, 8, ["onClick", "disabled"]))
                  : _createCommentVNode("", true),
                (_ctx.current === 0)
                  ? (_openBlock(), _createBlock(_component_a_button, {
                      key: 3,
                      type: "primary",
                      style: {"margin-right":"12px","height":"32px"},
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.saveStep0(false))),
                      disabled: 
              _ctx.keepFormStateTaskInfo === JSON.stringify(this.formState.taskInfo)
            
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" 保存 ")
                      ]),
                      _: 1
                    }, 8, ["disabled"]))
                  : _createCommentVNode("", true),
                (_ctx.current === 0)
                  ? (_openBlock(), _createBlock(_component_a_button, {
                      key: 4,
                      type: "primary",
                      style: {"margin-right":"12px","height":"32px"},
                      onClick: _ctx.saveStep0,
                      ghost: ""
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" 下一步"),
                        (
                _ctx.keepFormStateTaskInfo !==
                JSON.stringify(this.formState.taskInfo)
              )
                          ? (_openBlock(), _createElementBlock("span", _hoisted_8, "（保存）"))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (_ctx.current === 1)
                  ? (_openBlock(), _createBlock(_component_a_button, {
                      key: 5,
                      type: "primary",
                      style: {"margin-right":"12px","height":"32px"},
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.saveStep1('next'))),
                      ghost: ""
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" 下一步"),
                        (
                _ctx.keepTargetDataSelected !==
                JSON.stringify(this.getTargetDataSelected())
              )
                          ? (_openBlock(), _createElementBlock("span", _hoisted_9, "（保存）"))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.current === 2)
                  ? (_openBlock(), _createBlock(_component_a_button, {
                      key: 6,
                      type: "primary",
                      style: {"margin-right":"12px","height":"32px"},
                      onClick: _ctx.saveStep2
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" 保存 ")
                      ]),
                      _: 1
                    }, 8, ["onClick"]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ], 2),
        _createVNode(_component_TaskConfig, {
          ref: "TaskConfig",
          onConfigDone: _ctx.configDone,
          formState: _ctx.formState.taskInfo
        }, null, 8, ["onConfigDone", "formState"])
      ])
    ]),
    _: 1
  }, 8, ["spinning"]))
}