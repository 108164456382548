import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_range_picker = _resolveComponent("a-range-picker")!

  return (_openBlock(), _createBlock(_component_a_range_picker, {
    value: _ctx.formState.date,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.date) = $event)),
    style: {"width":"100%"},
    valueFormat: "YYYY-MM-DD",
    "disabled-date": _ctx.disabledDate,
    onChange: _ctx.change,
    size: _ctx.size,
    allowClear: false
  }, null, 8, ["value", "disabled-date", "onChange", "size"]))
}